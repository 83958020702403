import { AxiosInstance } from "../axiosInstance";
import APP_URL_USERSESSION from "src/constants/userSession";

const TOKEN = localStorage.getItem("token");

class ApiUserSession {
  async crearSession(data, token2) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERSESSION.CREATE_SESSION,
        data,
        {
          headers: { Authorization: `Bearer ${TOKEN || token2}` },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async verificarSessionActiva(user_id, token2) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERSESSION.VERIFIED_SESSION,
        { user_id },
        {
          headers: { Authorization: `Bearer ${TOKEN || token2}` },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async actualizarSession(data, token2) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERSESSION.UPDATE_SESSION,
        data,
        {
          headers: { Authorization: `Bearer ${TOKEN || token2}` },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async listarUsuarios(data, token) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERSESSION.LIST_USERS,
        data,
        {
          headers: { Authorization: `Bearer ${TOKEN || token}` },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }
}

export default new ApiUserSession();
