import React, { Component, Suspense, useEffect, useState } from "react";
import apiUsuarios from "src/providers/usuarios";
import apiUserSession from "src/providers/userSession";
import { HashRouter, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment-timezone";
import "moment/locale/es";
moment.locale("es");

const App = () => {
  const authState = useSelector((state) => state.authUser);
  const dataUser = useSelector((state) => state.dataUser);
  const token = useSelector((state) => state.token);

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Containers
  const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

  // Pages
  const Login = React.lazy(() => import("./views/pages/login/Login"));
  const LoginAdmin = React.lazy(() => import("./views/pages/login/LoginAdmin"));
  const Register = React.lazy(() => import("./views/pages/register/Register"));
  const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
  const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

  const verificarYCrearSession = async () => {
    try {
      // Primero verificamos si hay una sesión activa
      const verificacion = await apiUserSession.verificarSessionActiva(
        dataUser.id,
        token
      );

      if (!verificacion.hasActiveSession) {
        // Si no hay sesión activa, creamos una nueva
        const response = await apiUserSession.crearSession(
          {
            user_id: dataUser.id,
            login_timestamp: moment()
              .tz("America/Bogota")
              .format("YYYY-MM-DD HH:mm:ss"),
          },
          token
        );

        if (response.success) {
          console.log("Sesión iniciada correctamente");
        } else {
          console.error("No se pudo iniciar la sesión");
        }
      } else {
        console.log("Ya existe una sesión activa para este usuario");
      }
    } catch (error) {
      console.error("Error al verificar/registrar la sesión:", error);
    }
  };

  const cerrarSession = async () => {
    try {
      const response = await apiUserSession.actualizarSession(
        {
          user_id: dataUser.id,
          logout_timestamp: moment()
            .tz("America/Bogota")
            .format("YYYY-MM-DD HH:mm:ss"),
        },
        token
      );

      if (response.success) {
        console.log("Sesión cerrada correctamente");
      } else {
        console.error("No se pudo cerrar la sesión");
      }
    } catch (error) {
      console.error("Error al cerrar la sesión:", error);
    }
  };

  useEffect(() => {
    const auth = window.localStorage.getItem("auth");
    if (auth && auth === "true") {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const iniciarSesion = async () => {
      if (authState && dataUser?.id) {
        await verificarYCrearSession();
        try {
          await apiUsuarios.userOnline(dataUser.id, token);
        } catch (error) {
          console.error("Error actualizando estado online");
        }
      }
    };

    const handleOffline = async () => {
      if (dataUser?.id) {
        try {
          await cerrarSession();
          await apiUsuarios.userOffline(dataUser.id, token);
        } catch (error) {
          console.error("Error actualizando estado offline");
        }
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        handleOffline();
      }
    };

    // Iniciar sesión cuando se carga la aplicación
    iniciarSesion();

    // Agregar eventos
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleOffline);

    // Limpiar eventos
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleOffline);
    };
  }, [authState, dataUser]);

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        {!authState ? (
          <Routes>
            <Route
              exact
              path="/loginAdmin"
              name="Login Page Admin"
              element={<LoginAdmin />}
            />
            <Route exact path="*" name="Login Page" element={<Login />} />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        )}
      </Suspense>
    </HashRouter>
  );
};

export default App;
