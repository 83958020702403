const HOST = process.env.REACT_APP_API_URL;
const VERSION = process.env.REACT_APP_API_VERSION;

const APP_URL_USERS = {
  CREATE_USER: `${HOST}${VERSION}admin/dashboard/usuarios/crear-usuario`,
  LIST_USERS: `${HOST}${VERSION}admin/dashboard/usuarios/listar`,
  LIST_ALL_USERS: `${HOST}${VERSION}admin/dashboard/usuarios/listar-todos`,
  EDIT_USER: `${HOST}${VERSION}admin/dashboard/usuarios/editar-usuario`,
  DELETE_USER: `${HOST}${VERSION}admin/dashboard/usuarios/eliminar-usuario`,
  UPDATE_PHOTO: `${HOST}${VERSION}admin/dashboard/usuarios/foto`,
  UPDATE_FIRMA: `${HOST}${VERSION}admin/dashboard/usuarios/firma`,
  ACCESO_KIDS: `${HOST}${VERSION}admin/dashboard/usuarios/acceso-kids`,
  DENEGAR_KIDS: `${HOST}${VERSION}admin/dashboard/usuarios/denegar-kids`,
  USER_ONLINE: `${HOST}${VERSION}admin/dashboard/usuarios/user-online`,
  USER_OFFLINE: `${HOST}${VERSION}admin/dashboard/usuarios/user-offline`,
  USER_ONLINE_COUNT: `${HOST}${VERSION}admin/dashboard/usuarios/user-online-count`,
  USERS_CUMPLEANEROS: `${HOST}${VERSION}admin/dashboard/usuarios/listar-cumpleaneros`,
};

export default APP_URL_USERS;
