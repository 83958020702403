const HOST = process.env.REACT_APP_API_URL;
const VERSION = process.env.REACT_APP_API_VERSION;

const APP_URL_USERSESSION = {
  // crear sesion
  CREATE_SESSION: `${HOST}${VERSION}admin/dashboard/userSession/crear-session`,
  // verified sesion
  VERIFIED_SESSION: `${HOST}${VERSION}admin/dashboard/userSession/verificar-session`,
  // actualizar sesion
  UPDATE_SESSION: `${HOST}${VERSION}admin/dashboard/userSession/actualizar-session`,
  // listar usuarios
  LIST_USERS: `${HOST}${VERSION}admin/dashboard/userSession/listar-usuarios`,
};

export default APP_URL_USERSESSION;
