import { AxiosInstance } from "../axiosInstance";
import APP_URL_USERS from "src/constants/usuarios";

const TOKEN = localStorage.getItem("token");

class ApiUsuarios {
  async crearUsuario(data) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERS.CREATE_USER,
        data,
        {
          headers: { Authorization: `Bearer ${TOKEN}` },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async eliminarUsuario(id) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERS.DELETE_USER,
        { id },
        {
          headers: { Authorization: `Bearer ${TOKEN}` },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async editarUsuario(data, token2) {
    try {
      const response = await AxiosInstance.post(APP_URL_USERS.EDIT_USER, data, {
        headers: { Authorization: `Bearer ${TOKEN || token2}` },
      });
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async listarUsuarios() {
    try {
      const response = await AxiosInstance.get(APP_URL_USERS.LIST_USERS, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async listarTodosUsuarios(token2) {
    try {
      const response = await AxiosInstance.get(APP_URL_USERS.LIST_ALL_USERS, {
        headers: { Authorization: `Bearer ${TOKEN || token2}` },
      });
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  async actualizarFoto(formData, token2) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERS.UPDATE_PHOTO,
        formData,
        {
          headers: {
            Authorization: `Bearer ${TOKEN || token2}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }
  async actualizarFirma(formData, token2) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERS.UPDATE_FIRMA,
        formData,
        {
          headers: {
            Authorization: `Bearer ${TOKEN || token2}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  // ACCESO KIDS
  async accesoKids(id) {
    try {
      const response = await AxiosInstance.post(APP_URL_USERS.ACCESO_KIDS, id, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      });
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  // DENEGAR KIDS
  async denegarKids(id) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERS.DENEGAR_KIDS,
        id,
        {
          headers: { Authorization: `Bearer ${TOKEN}` },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  // USER ONLINE
  async userOnline(id, token2) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERS.USER_ONLINE,
        { id },
        {
          headers: { Authorization: `Bearer ${TOKEN || token2}` },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  // USER OFFLINE
  async userOffline(id, token2) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERS.USER_OFFLINE,
        { id },
        {
          headers: { Authorization: `Bearer ${TOKEN || token2}` },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }
  // USER ONLINE COUNT
  async userOnlineCount(token2) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERS.USER_ONLINE_COUNT,
        {},
        {
          headers: { Authorization: `Bearer ${TOKEN || token2}` },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }

  // USERS CUMPLEAÑEROS
  async usersCumpleaneros(fecha, token2) {
    try {
      const response = await AxiosInstance.post(
        APP_URL_USERS.USERS_CUMPLEANEROS,
        { fecha },
        {
          headers: { Authorization: `Bearer ${TOKEN || token2}` },
        }
      );
      return { success: true, response: response.data };
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  }
}

export default new ApiUsuarios();
